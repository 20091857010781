<template>
    <router-link :to="`/articles/${article.id}`" class="article-link">
      <div class="article-item">
        <div class="image">
          <img :src="require(`@/assets/img/articles/${article.img}`)" alt="" />
        </div>
        <div class="content">
          <div class="time">
            <icon-clock class="icon-clock" />
            <span class="text-label">Время:</span>&nbsp;<span>{{ article.time }}</span>
          </div>
          <h3 class="title">{{ article.title }}</h3>
          <p class="description">{{ article.preview }}</p>
        </div>
      </div>
    </router-link>
  </template>
  <script>
  import IconClock from '@/assets/img/icons/clock.svg'
  export default {
    name: 'ArticleItem',
    components: {
      IconClock
    },
    props: {
      article: {
        type: Object,
        required: true
      }
    }
  }
  </script>
  <style scoped>
  .article-link {
    text-decoration: none;
    color: inherit;
  }
  .article-item {
    display: flex;
    flex-direction: column;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    transition: box-shadow 0.3s;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 12px 12px rgba(0, 0, 0, 0.02), 0px 16px 16px rgba(0, 0, 0, 0.08);
    &:hover {
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    }
    .image {
      width: 100%;
      height: 200px;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content {
      padding: 16px;
      .time {
        display: flex;
        align-items: center;
        margin-bottom: 8px;
        .icon-clock {
          margin-right: 8px;
          width: 15px;
          height: 14px;
          path {
            fill: #333;
            stroke: #333;
          }
        }
        .text-label {
          color: #888;
        }
      }
      .title {
        font-size: 1.2em;
        font-weight: bold;
        margin-bottom: 8px;
        color: #333;
      }
      .description {
        color: #666;
        font-weight: 300;
      }
    }
  }
  </style>