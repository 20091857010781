<template>
  <full-page-template>
    <div class="bg-article" :class="{ active: loaded }" />
    <div class="p-article">
      <div class="container">
        <div class="wrapper">
          <article class="article mb-xl">
            <div class="content">
              <div class="wrapper-img">
                <img :src="require(`@/assets/img/articles/${article.img}`)" alt="" />
              </div>
              <div class="info">
                <div class="time mb-xs">
                  <icon-clock class="icon-clock" />
                  <span class="text-label">Время:</span>&nbsp;<span>{{ article.time }}</span>
                </div>
                <social-sharing-button :share-object="article" :url-path="urlPath" />
              </div>
              <h2 class="fw--bold fs--medium mb-xs">
                {{ article.title }}
              </h2>
              <div class="editor fs--small mb-xs" v-html="article.description" />
              <app-button title="Защитить питомца" description="Страница продукта">
                <icon-debug class="icon-protect" />
              </app-button>
            </div>
          </article>
          <div class="divider" />
          <p class="other-articles ta--center">Другие статьи</p>
          <div class="grid">
            <div
              v-for="item in otherArticles"
              :key="item.id"
              class="col"
            >
              <article-item :article="item" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <app-footer />
  </full-page-template>
</template>

<script>
import AppFooter from '@/components/common/AppFooter'
import FullPageTemplate from '@/layouts/FullPageTemplate'
import ArticleItem from '@/pages/articles/ArticleItem'
import AppButton from '@/components/common/AppButton'
import IconClock from '@/assets/img/icons/clock.svg'
import IconDebug from '@/assets/img/icons/debug.svg'
import SocialSharingButton from '@/components/SocialSharingButton'
import articles from '@/data/articles' // Import your articles data

export default {
  name: 'ArticleDetail',
  components: {
    AppFooter,
    FullPageTemplate,
    ArticleItem,
    AppButton,
    IconClock,
    IconDebug,
    SocialSharingButton
  },
  data() {
    return {
      loaded: false,
      article: {},
      otherArticles: []
    }
  },
  computed: {
    urlPath() {
      return window.location.href
    }
  },
  methods: {
    fetchArticle() {
      // Get the article ID from the route parameters
      const articleId = parseInt(this.$route.params.id)

      // Find the article by ID
      this.article = articles.find((a) => a.id === articleId)

      // If the article is not found, redirect or show an error
      if (!this.article) {
        this.$router.push('/404')
        return
      }

      // Get other articles excluding the current one
      this.otherArticles = articles.filter((a) => a.id !== articleId)
    }
  },
  mounted() {
    // Set the loaded state
    if (document.readyState === 'complete') {
      this.loaded = true
    } else {
      window.onload = () => {
        this.loaded = true
      }
    }

    // Fetch the article data when the component is mounted
    this.fetchArticle()
  },
  watch: {
    // Remove parameters since they are not used
    '$route.params.id': function() {
      this.fetchArticle()
    }
  }
}
</script>
<style lang="scss" scoped>
.bg-article {
  &.active {
    opacity: 1;
    transform: none;
  }
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('~@/assets/img/bg-articles.png') center / cover no-repeat;
  background-attachment: fixed;
  opacity: 0;
  transition: 1s;
}

.p-article {
  position: relative;
  min-height: 100vh;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .article {
    padding-top: 66px;
    padding-left: 36px;
    position: relative;
    max-width: 864px;

    @media (max-width: 576px) {
      padding-top: 8px;
      padding-left: 8px;
    }

    .wrapper-img {
      z-index: 1;
      position: absolute;
      left: -36px;
      top: -66px;
      width: 358px;
      height: 172px;
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.04))
        drop-shadow(0px 12px 12px rgba(0, 0, 0, 0.02))
        drop-shadow(0px 16px 16px rgba(0, 0, 0, 0.08));
      border-radius: 8px;
      overflow: hidden;

      @media (max-width: 576px) {
        top: -8px;
        left: -8px;
        width: 140px;
        height: 100px;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }

    .content {
      position: relative;
      padding: 26px 16px;
      background: linear-gradient(
        180deg,
        rgba(250, 250, 250, 0.4) 0%,
        rgba(255, 255, 255, 0.4) 100%
      );
      backdrop-filter: blur(10px);
      border-radius: 16px;
      display: flex;
      flex-direction: column;

      .info {
        align-self: flex-end;
        padding-left: 150px;
        margin-bottom: 72px;

        @media (max-width: 576px) {
          margin-bottom: 36px;
          padding-left: 130px;
        }

        .time,
        .share {
          display: flex;
          align-items: center;

          .text-label {
            color: #888;
          }

          .icon-clock {
            margin-right: 8px;
            width: 15px;
            height: 14px;

            path {
              fill: #333;
              stroke: #333;
            }
          }
        }
      }

      .icon-protect {
        path {
          fill: #333;
          stroke: #333;
        }
      }

      .editor {
        font-weight: 300;

        p {
          margin-bottom: 16px;
        }
      }
    }
  }

  .other-articles {
    font-weight: 300;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, 400px);
    grid-gap: 32px;
    justify-content: center;

    @media (max-width: 576px) {
      grid-template-columns: repeat(auto-fill, 300px);
      grid-template-columns: 1fr;
    }

    .col {
      width: 100%;
    }
  }
}
</style>