// src/data/articles.js

export default [
    {
      id: 1,
      img: 'article-1.jpg',
      time: '3 мин.',
      title: 'Вы знаете, что по статистике больше всего собак теряется в летний период?',
      preview: 'Это связано с тем, что многие вывозят своих любимцев на дачи и берут с собой в путешествия...',
      description: '<p>Это связано с тем, что многие вывозят своих любимцев на дачи и берут с собой в путешествия, и часто животное теряет голову от неожиданной свободы.</p><p>Есть несколько способов обезопасить любимцев, среди которых чипирование и кулоны-адресники с телефоном хозяина.</p><p>CLICKTOID предлагает Вам специальный кулон для ошейника с индивидуальным ID и телефоном горячей линии 8 800 4444 308, это исключит использование Ваших персональных данных в открытом доступе, и мошенники не смогут воспользоваться ими.</p>'
    },
    {
      id: 2,
      img: 'article-2.jpg',
      time: '3 мин.',
      title: 'Забота о близких',
      preview: 'Люди преклонного возраста, как и дети, увы, часто теряются...',
      description: '<p>Люди преклонного возраста, как и дети, увы, часто теряются. Бывает, что пожилой человек вышел в магазин и четко понимает, куда и зачем идет, а бывает, что вышел и не может вспомнить дорогу домой. При этом, как правило, пожилые люди не признаются в том, что потерялись, и это затрудняет их поиск.</p><p>CLICKTOID предлагает Вам специальные кулоны с индивидуальным ID и телефоном горячей линии 8 800 4444 308, что позволит быстро найти и вернуть близкого человека домой, не раскрывая Ваши персональные данные.</p>'
    }
    // Add more articles as needed
  ]